import React, { useEffect, useRef, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { motion } from "framer-motion";
import bodyimg from "../assets/body shape.jpg";
import "./New_widget.css";
import { createRoot } from "react-dom/client";
import logo from "../assets/Logo.webp";
import { Image, Upload, Input, Button, Alert } from "antd";
import Male from "../assets/male.png";
import Female from "../assets/female.png";
import * as poseDetection from "@tensorflow-models/pose-detection";
import * as tf from "@tensorflow/tfjs";
import { errorNotification, successNotification } from "../Helper/Notification";
import { uploadImageApiHandler } from "../Service/Demo.service";
import { p } from "framer-motion/client";

const New_Widget = ({ onClose }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [aligned, setAligned] = useState(false);
  const [Submit, setSubmit] = useState(false);
  const [messagePreview, setMessagePreview] = useState();
  const [successMessagePreview, setSuccessMessagePreview] = useState();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState([]);
  const [productData, setProductData] = useState(null); // [{}
  const [cameraActive, setCameraActive] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedGender, setSelectedGender] = useState("");
  console.log("selectedGender ---------", selectedGender);
  const [activeTab, setActiveTab] = useState("rightFit");
  const [showDialog, setShowDialog] = useState(true); // State to handle dialog visibility
  const [environment, setEnvironment] = useState(""); // State to store the camera environment ['user', 'environment'
  const [cameraOpen, setCameraOpen] = useState(false);
  const videoRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [heights, setHeights] = useState("");
  const overlayCanvasRef = useRef(null);
  const detectorRef = useRef(null);

  const [hasPhoto, setHasPhoto] = useState(false);
  const photoRef = useRef(null);
  const stepRef = useRef(null);

  useEffect(() => {
    if (stepRef.current) {
      stepRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [currentStep]);

  const changeEnvironment = () => {
    setEnvironment(environment == "environment" ? "user" : "environment");
    closeCamera();
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const takePhoto = () => {
    if (!photoRef.current || !videoRef.current) return;

    // const ctx = photoRef.current.getContext("2d");
    // ctx.drawImage(
    //   videoRef.current,
    //   0,
    //   0,
    //   photoRef.current.width,
    //   photoRef.current.height
    // );
    // console.log("Photo captured");
    // // let photo = photoRef.current.toDataURL("image/png");
    // let imageDataUrl = photoRef.current.toDataURL("image/png");
    const windowwidth = window.innerWidth;
    const width = windowwidth > 600 ? 600 : 264;
    const height = windowwidth > 600 ? 500 : 340.16;

    let video = videoRef.current;
    let photo = photoRef.current;

    if (!video || !photo) {
      console.error("Camera or photo elements are not available.");
      return; // Exit the function if elements are not available
    }
    photo.width = width;
    photo.height = height;

    let ctx = photo.getContext("2d");
    ctx.drawImage(video, 0, 0, width, height);
    const imageDataUrl = photo.toDataURL("image/png");
    setPreviewImage(imageDataUrl);
    base64ToFile(imageDataUrl, "image.png", "image/png");
    // Stop the camera stream after capturing
    closeCamera();
  };

  function base64ToFile(base64, fileName, mimeType) {
    // Split the base64 string into parts
    const parts = base64.split(";base64,");
    const contentType = parts[0].split(":")[1] || mimeType; // Provide mime type if not present in base64 string
    const raw = window.atob(parts[1]); // Decode Base64 string
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength); // Create a uint8Array

    // Populate the array with character codes from the decoded string
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    // Create a Blob with the Uint8Array
    const blob = new Blob([uInt8Array], { type: contentType });
    console.log("blob -----DATA---------", blob);
    setImage(blob);
    setSelectedGender(selectedGender);
    dataUploadhandler(blob, selectedGender);

    // Return a File object from the Blob
    return new File([blob], fileName, {
      type: blob.type,
      lastModified: Date.now(), // Optionally set the last modified date to now
    });
  }

  const skeletonBoundaries = {
    xMin: 100, // Left boundary of the skeleton
    xMax: 400, // Right boundary of the skeleton
    yMin: 100, // Top boundary of the skeleton
    yMax: 600, // Bottom boundary of the skeleton
  };

  // Ensure TensorFlow.js is initialized

  const startPoseDetection = async () => {
    // Ensure TensorFlow.js is initialized
    await initializeTF();

    // Initialize pose detector
    detectorRef.current = await poseDetection.createDetector(
      poseDetection.SupportedModels.MoveNet,
      { modelType: poseDetection.movenet.modelType.SINGLEPOSE_LIGHTNING }
    );

    const detectPose = async () => {
      if (!videoRef.current || !detectorRef.current) return;

      // Detect pose
      const poses = await detectorRef.current.estimatePoses(videoRef.current);

      if (poses && poses.length > 0) {
        const keypoints = poses[0].keypoints;

        // Draw keypoints on the overlay canvas
        drawKeypoints(keypoints);

        // Check if keypoints fit within the skeleton boundaries
        const isAligned = keypoints.every((kp) => {
          return (
            kp.score > 0.5 && // Confidence threshold
            kp.x > skeletonBoundaries.xMin &&
            kp.x < skeletonBoundaries.xMax &&
            kp.y > skeletonBoundaries.yMin &&
            kp.y < skeletonBoundaries.yMax
          );
        });

        setAligned(isAligned);

        // If aligned, take photo automatically
        if (isAligned) {
          console.log("Pose aligned with skeleton!");
          takePhoto();
        }
      }

      // Continue detection loop
      requestAnimationFrame(detectPose);
    };

    detectPose();
  };

  const drawKeypoints = (keypoints) => {
    const ctx = overlayCanvasRef.current.getContext("2d");
    ctx.clearRect(
      0,
      0,
      overlayCanvasRef.current.width,
      overlayCanvasRef.current.height
    );

    // Draw each keypoint
    keypoints.forEach((kp) => {
      if (kp.score > 0.5) {
        ctx.beginPath();
        ctx.arc(kp.x, kp.y, 5, 0, 2 * Math.PI); // Draw a circle for each keypoint
        ctx.fillStyle = "red"; // Red color for visibility
        ctx.fill();

        // Optional: Label the keypoint (e.g., 'Shoulder', 'Hand')
        ctx.font = "10px Arial";
        ctx.fillStyle = "white";
        ctx.fillText(kp.name, kp.x + 6, kp.y - 6);
      }
    });
  };

  // const closeCamera = () => {
  //   const videoElement = videoRef.current;
  //   if (videoElement && videoElement.srcObject) {
  //     const tracks = videoElement.srcObject.getTracks();
  //     tracks.forEach((track) => track.stop());
  //     videoElement.srcObject = null;
  //   }
  //   setCameraOpen(false);
  //   setHasPhoto(false);
  // };
  // Ensure TensorFlow.js is initialized
  const initializeTF = async () => {
    await tf.ready(); // Wait for TensorFlow.js to be ready
    await tf.setBackend("webgl"); // Use WebGL backend
    console.log(`Using TensorFlow.js backend: ${tf.getBackend()}`);
  };

  const closeCamera = () => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
    setCameraOpen(false);
    detectorRef.current = null; // Cleanup detector
  };
  useEffect(() => {
    if (environment != "") {
      handleOpenCamera();
    }
  }, [environment]);
  const handleOpenCamera = async () => {
    // Check if getUserMedia is available
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Camera not supported in your browser.");
      console.error("Camera not supported in your browser.");
      return;
    }

    await initializeTF(); // Ensure TensorFlow.js is ready

    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: environment, // Request the back camera
        },
      })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        setCameraOpen(true);

        // Set canvas dimensions to match video dimensions
        videoRef.current.onloadedmetadata = () => {
          overlayCanvasRef.current.width = videoRef.current.videoWidth;
          overlayCanvasRef.current.height = videoRef.current.videoHeight;
        };

        // Start pose detection
        startPoseDetection();
      })
      .catch((err) => {
        console.error("Error accessing camera:", err);
        alert("Failed to access camera. Please check your browser settings.");
      });
    // navigator.mediaDevices
    //   .getUserMedia({
    //     video: {
    //       facingMode: environment, // Request the back camera
    //     },
    //   })
    //   .then((stream) => {
    //     let video = videoRef.current;
    //     video.srcObject = stream;
    //     video.play();
    //     startPoseDetection();
    //   })
    //   .catch((err) => {
    //     setCameraOpen(false);
    //     console.error("error:", err);
    //   });
  };

  const handleGenderSelect = (gender) => {
    console.log("----------------------------------", gender);
    setSelectedGender(gender);
    setCurrentStep(3);
    if (!cameraOpen) {
      handleOpenCamera();
    }
  };

  const handleAccept = () => {
    setShowDialog(false);
    setCurrentStep(2);
  };

  const handleDecline = () => {
    onClose();
  };

  const dataUploadhandler = async (image, selectedGender) => {
    try {
      setLoading(true);
      console.log("selectedGender Upper to the API call", selectedGender);
      if (!image) {
        return errorNotification("Please upload an image");
      }

      // if (selectedGender == "") {
      //   return errorNotification("Please select a gender");
      // }
      const formData = new FormData();
      formData.append("image", image);
      formData.append("known_height", "5.6");
      formData.append("gender", selectedGender || "Male");
      const { data, message, success } = await uploadImageApiHandler(formData);
      if (success) {
        setTotalData(data);
        setProductData(data);
        //remove privious message
        setMessagePreview();
        setSuccessMessagePreview(message);
        setSubmit(true);
      } else {
        setTotalData([]);
        setSubmit(true);
        setSuccessMessagePreview();
        setMessagePreview(message);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <motion.div
      className="modal-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* Dialog Box */}
      {showDialog && (
        <motion.div
          className="dialog-box"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <span style={{ fontSize: "20px", fontWeight: 600 }}>
            Privacy Statement
          </span>
          <p style={{ fontSize: "small", fontWeight: 400 }}>
            We respect your privacy and do not store any personal information.
            All data is processed in real-time and not retained, ensuring
            complete confidentiality.
          </p>
          <div className="dialog-buttons">
            <button className="btn" onClick={handleAccept}>
              Accept
            </button>
            <button className="borderbtn" onClick={handleDecline}>
              Decline
            </button>
          </div>
        </motion.div>
      )}

      {/* Main Widget */}
      {!showDialog && (
        <motion.div
          className="modal-content1"
          initial={{ x: 100 }}
          animate={{ x: 0 }}
          exit={{ x: -100 }}
          delay={1}
          transition={{ type: "spring", stiffness: 120 }}
        >
          <div
            style={{ position: "sticky", top: "0", left: "0", zIndex: "1000" }}
          >
            <div className="header">
              {/* <h4 id="widget-title">SizeSutra</h4> */}
              <img src={logo} alt="logo" style={{ width: "100px" }} />
              <div style={{ display: "flex", gap: "10px" }}>
                <IoMdClose
                  size={20}
                  onClick={onClose}
                  style={{ cursor: "pointer" }}
                  aria-label="Close"
                  className="close-modal-btn"
                />
              </div>
            </div>
          </div>

          <div className="body">
            {currentStep >= 2 && (
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                ref={stepRef}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    marginBottom: "10px",
                  }}
                >
                  Select your Gender
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="gender">
                    <button
                      className={`leftbtn ${
                        selectedGender === "Male" ? "selected" : ""
                      }`}
                      onClick={() => handleGenderSelect("Male")}
                      disabled={previewImage}
                    >
                      Male
                    </button>
                    <button
                      className={`leftbtn ${
                        selectedGender === "Female" ? "selected" : ""
                      }`}
                      onClick={() => handleGenderSelect("Female")}
                      disabled={previewImage}
                    >
                      Female
                    </button>
                    {/* <button
                      className={`leftbtn ${
                        selectedGender === "Other" ? "selected" : ""
                      }`}
                      onClick={() => handleGenderSelect("Other")}
                    >
                      Other
                    </button> */}
                  </div>
                  {selectedGender && (
                    <div className="rightsidepart">
                      <button className="rightbtn">{selectedGender}</button>
                    </div>
                  )}
                </div>
              </motion.div>
            )}
            {/* Step 3: Camera Section */}
            {currentStep >= 3 && (
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                ref={stepRef}
              >
                {/* <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    marginBottom: "10px",
                  }}
                >
                  Open Camera and take picture
                </div> */}
                {/* change 9-12  */}
                <div
                  className="sidepart"
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {/* {
                    <img
                    style={{
                      position: "absolute",
                      top: "60%", 
                      left: "50%", 
                      transform: "translate(-50%, -50%)", 
                      zIndex: 10,
                      color: "white",
                      fontSize: "100px",
                      textShadow: "0 0 1px black", 
                      display: `${cameraOpen ? "block" : "none"}`,
                      maxWidth: "100%",
                      objectFit: "contain",
                    }}
                      src={selectedGender == "Male" ? Male : Female}
                      alt="Preview"
                      className="photoresize"
                    />
                  } */}
                  <video
                    ref={videoRef}
                    autoPlay
                    playsInline
                    muted
                    className="videoresize"
                    style={{
                      width: "100%",
                      display: `${cameraOpen ? "block" : "none"}`,
                    }}
                  ></video>
                  {
                    <img
                      style={{
                        position: "absolute",
                        top: "60%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 10,
                        color: "white",
                        fontSize: "100px",
                        textShadow: "0 0 1px black",
                        display: `${cameraOpen ? "block" : "none"}`,
                        maxWidth: "100%",
                        objectFit: "contain",
                      }}
                      src={selectedGender == "Male" ? Male : Female}
                      alt="Preview"
                      className="photoresize"
                    />
                  }
                  {!previewImage && (
                    <canvas
                      ref={overlayCanvasRef}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 3,
                        pointerEvents: "none",
                      }}
                    ></canvas>
                  )}

                  <canvas
                    ref={photoRef}
                    style={{
                      display: "none",
                      width: "100%",
                      height: "100%",
                    }}
                  ></canvas>
                </div>
                {/* end 9-12 */}

                {/* {cameraOpen && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <Button type="primary" onClick={closeCamera}>
                      Close Camera
                    </Button>
                    <Button type="primary" onClick={takePhoto}>
                      Take Photo
                    </Button>
                    <Button type="primary" onClick={() => changeEnvironment()}>
                      Change Camera
                    </Button>
                  </div>
                )} */}
                {cameraActive && (
                  <div className="rightsidepart">
                    <motion.div
                      className="rightbtn"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                    >
                      <p>Camera is opening... Please wait for 4 seconds.</p>
                    </motion.div>
                  </div>
                )}
                <Image.PreviewGroup
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: setPreviewOpen,
                  }}
                >
                  <Image src={previewImage} style={{ display: "none" }} />
                  <img src={previewImage} style={{ display: "none" }} />
                </Image.PreviewGroup>
              </motion.div>
            )}
            {/* Step 4: Tab Navigation */}
            {/* {currentStep >= 4 && ( */}
            {productData?.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  flexDirection: "column",
                }}
              >
                <p>
                  {" "}
                  Right Feet :-{" "}
                  {productData &&
                    productData.find((item) => item.name === "right_size")
                      ?.parameters}
                </p>
                <p>
                  {" "}
                  Loose Feet :-{" "}
                  {productData &&
                    productData.find((item) => item.name === "lose_size")
                      ?.parameters}
                </p>
                <p>
                  {" "}
                  TiteFeet :-{" "}
                  {productData &&
                    productData.find((item) => item.name === "fit_size")
                      ?.parameters}
                </p>
              </div>
            ) : (
              previewImage && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    flexDirection: "column",
                  }}
                >
                  No Data Found as per your selection
                </div>
              )
            )}
          </div>
        </motion.div>
      )}
    </motion.div>
  );
};

const Table = ({ columns, data }) => {
  return (
    <table className="fit-table">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>{column.header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {columns.map((column, idx) => (
              <td key={idx}>{row[column.accessor]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

// Add this function at the bottom of your file
window.loadWidget = function () {
  const container = document.getElementById("react-root");
  const root = createRoot(container); // Create a root for the React component

  root.render(
    <New_Widget
      onClose={() => {
        root.unmount(); // Unmount the component from the root
      }}
    />
  );
};

export default New_Widget;
