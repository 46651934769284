import React, { useState } from 'react'
import New_Widget from './New_Widget';
import { BiSolidWebcam } from 'react-icons/bi';

const New_widget_popup = () => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
  
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <button onClick={handleOpenModal} className="btn" style={{display:'flex', gap:'10px',alignItems:'center'}}>SizeSutra 
          <BiSolidWebcam  />
        </button>
  
        {showModal && (
          <New_Widget onClose={handleCloseModal} />
        )}
      </div>
    );
}

export default New_widget_popup