import './App.css';
import New_widget_popup from './Component/New_widget_popup';
function App() {
  return (
    <div >
      <New_widget_popup />
    </div>
  );
}

export default App;
